import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import { getNewData, logout } from '../actions/index';
import WhatsNewComponent from '../components/WhatsNewComponent';
import { trackGA } from '../utils';
import { WHATS_NEW } from '../constants/modules';
import Profile from '../utils/Profile';

WhatsNew.propTypes = {
  newObj: PropTypes.object,
  getNewData: PropTypes.func,
  profile: Profile.PropTypes,
  logout: PropTypes.func
};

function WhatsNew({newObj, getNewData, profile, logout}) {
  const location = useLocation();
  let startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);
  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 21);
  useEffect(() => {
    trackGA(WHATS_NEW, location);
  }, [location]);
  useEffect(() => {
    if (profile) {
      startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      endDate = new Date();
      endDate.setMonth(endDate.getMonth() + 3);
      getNewData(startDate.toISOString(), endDate.toISOString());
    } else {
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      endDate = new Date();
      endDate.setDate(endDate.getDate() + 21);
      getNewData(startDate.toISOString(), endDate.toISOString());
    }
  }, []);
  return (
    <WhatsNewComponent
      newObj={newObj}
      profile={profile}
      logout={logout}
    />
  );
}

const mapStateToProps = state => {
  const { newReducer } = state;
  const profile = state.user.profile && new Profile(state.user.profile);
  return {
    newObj: newReducer,
    profile: profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewData: (startDate, endDate) => dispatch(getNewData(startDate, endDate)),
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNew);
