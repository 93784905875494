export const filtersFormat = {
  amazon: 'Amazon Prime',
  appleTv: 'Apple TV+',
  'disney+': 'Disney+',
  netflix: 'Netflix',
  hulu: 'Hulu',
  linear: 'TV',
  channel: 'YouTube (Channel)',
  video: 'Youtube (Video)'
};
