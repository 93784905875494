import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Header from './Header';
import Footer from './Footer';

import styles from './PrivacyPolicy.module.css';

export default function TermsOfService() {
  return (
    <Container fluid={true} className={`${styles.Privacy} privacy`}>
      <Header />
      <Row>
        <Col lg={12} className={`${styles.baseTitle} d-flex align-items-center`}>
          <span>Terms of Service</span>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Acceptance</div>
          <p>watchNXT’s website watchNXT.com (the <b>Site</b>) is powered by Diesel Labs, Inc. <b>(Diesel Labs)</b>.  The Site,
          together with all of the data <b>(watchNXT Data)</b>, functionalities, materials, documents, text, images, graphics, animation,videos and other information and content (collectively, <b>Content</b>) and any and all services, watchNXT products (including, but not limited to, the Chrome Extension), applications, and other web or browser extensions provided at the Site or otherwise provided by watchNXT, are collectively referred to as the <b>Service</b>.</p>
          <p>The Service is offered and provided subject to acceptance without modification of all of the terms and conditions contained herein <b>(Terms of Service)</b>.  The Terms of Service shall be deemed to include all other operating rules, conditions, policies and procedures that are referred to herein or that may otherwise be published by watchNXT, from time to time, at the Site (collectively, <b>Policies</b>), including without limitation, the Privacy Policy. If you are registering for, using, or accessing the Site or Service on behalf of a company, third party or other entity, you represent and warrant that you are authorized to bind such entity to the provisions hereof.</p>
          <p>IF YOU DO NOT AGREE TO ALL OF THESE TERMS OF SERVICE, OR IF YOU ARE NOT ELIGIBLE OR AUTHORIZED TO ENTER INTO THIS AGREEMENT, THEN DO NOT REGISTER FOR, ACCESS OR USE THE SERVICE.  COMPLETING OUR REGISTRATION PROCESS OR OTHERWISE ACCESSING OR USING ALL OR ANY PART OF THE SERVICE WILL CONSTITUTE ACCEPTANCE OF, AND CREATE A LEGALLY ENFORCEABLE CONTRACT UNDER WHICH YOU AGREE TO BE BOUND BY, ALL OF THESE TERMS OF SERVICE, WITHOUT MODIFICATION.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Updates</div>
          <p>watchNXT reserves the right, at its sole discretion, to update, modify or replace the Terms of Service (including any Policy), in whole or in part, at any time.  watchNXT will use reasonable efforts to provide notice of any material change in advance of the effective date of that change.  Change notices may be communicated by postings at the Site, by email or otherwise.  In any case, you should periodically check the Policies and other Terms of Service for changes.  Continued access or use of the Service following any change to the Terms of Service constitutes your acceptance of those changes.  The Terms of Service may not otherwise be amended, as they apply to you, except by a written agreement executed by you and watchNXT.</p>
          <p>watchNXT may update, modify, suspend or terminate the Service (including without limitation, the Site or any Content), in whole or in part, at any time with or without notice, you agree that watchNXT shall not be liable to you or to any third party for any modification, suspension, or termination of the Service.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Eligibility</div>
          <p>watchNXT intends that the Service be made available only for your own internal, personal, noncommercial use, and not on behalf of or for the benefit of any third party, and only to individuals who are at least 13 years old.  You will only use the Service in a manner that complies with all laws that apply to you. If you do not qualify, then you are prohibited from accessing, registering for or using the Service.  Subject to compliance with applicable laws and regulations, watchNXT may refuse to offer or continue offering the Service to any person and may change its eligibility criteria from time to time.</p>
          <p>We do not knowingly collect or solicit personally identifiable information from children under 13; if you are a child under 13, please do not attempt to register for the Services or send any personal information about yourself to us. If we learn we have collected personal information from a child under 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at <a href="mailto:support@watchNXT.com">support@watchNXT.com</a>.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Registration</div>
          <p>You may be required to register with watchNXT to access the Service, at your sole discretion</p>
          <p>If you do not register with watchNXT, you may still visit the Site and use certain general features of the Service, but not the features for which registration is necessary.</p>
          <p>If you choose to register with watchNXT, then you will be required to provide at least an email address in order to establish your registration.  You are required to maintain and update your registration data from time to time, to ensure that it is current, complete and accurate.  You may be required to provide additional information that is necessary to provide you with advanced Service features.</p>
          <p>watchNXT may refuse to accept your registration application, in its sole discretion.  You will be solely liable for any and all activities under your registration.  You agree to notify watchNXT immediately of any unauthorized use of the Service under your registration or any other breach of security related to the Service.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Fees and Payments</div>
          <p>At this time, the Service may be accessed and used without charge, but each user is responsible for procuring all equipment and services needed to connect to the Service (such as, for example, mobile devices, computers, Internet services and cellular data services). Any other services to be rendered by  shall be agreed to in a separate contract executed by the parties. In the event there is a conflict between this Terms of Service and the separate contract, the terms of the separate contract will govern. watchNXT reserves the right to charge for certain or all Services in the future.  We will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you must pay all applicable fees for such Services.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Privacy</div>
          <p>watchNXT's current <b>Privacy Policy</b> is available at <a href="https://www.watchNXT.com/privacy">https://www.watchNXT.com/privacy</a>.  It is important that you read and understand the Privacy Policy, especially because the Service may involve the collection and use of personally identifiable information and other personal data <b>(Personal Data)</b>.</p>
          <p>The most current Privacy Policy will apply to any and all use of the Site and other aspects of the Service.  watchNXT will not intentionally edit, modify, delete or disclose the contents of your Personal Data or other Service Data (as defined below) in connection with the Service except (a) as reasonably necessary for watchNXT (or its service providers) to operate the Service, including processing and responding to your inputs, (b) to communicate with you, (c) as otherwise permitted under the Privacy Policy or elsewhere in the Terms of Service, (d) when watchNXT reasonably believes that such action is necessary to conform or comply with any legal, regulatory, law enforcement or similar requirement or investigation, to protect or defend the rights or property of watchNXT or any third party or to enforce the Terms of Service or (e) as otherwise authorized by you.</p>
          <p>watchNXT will not collect personally identifiable information from any person who is actually known to us to be under the age of 13.  If we become aware that a person under 13 has provided personally identifiable information, watchNXT will take steps to remove such information and terminate that individual's registration, access and use of the Service.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Service Data and Site Analytics</div>
          <p>The Service collects, stores, processes and analyzes Service Data and produces Site Analytics.  Accordingly, whenever you interact with the Service, a variety of technologies are employed to automatically or passively collect Service Data.</p>
          <p>The term <b>Service Data</b> means all data and information that are provided by you during registration and subsequent use of the Service, and all other data and information about you that are otherwise discerned or collected by watchNXT based on your access and use of the Service.  For clarity, Service Data includes, but is not limited to:  Personal Data; browser type; device type; device identifiers; operating system; time of day; identification of Site page views; use of particular Service features; IP address; identification of television shows, movies and other media viewed or streamed; time spent viewing or streaming television shows, movies and other media; identification of third party websites, streaming services or apps.</p>
          <p>The term <b>Site Analytics</b> means statistics, metrics, abstractions and other analyses that are based on or derived from the Service Data and/or your use of the Service (including without limitation, measurements of Service usage and performance), which are developed in a manner that does not disclose the identity of any user (such as, in the aggregate with other data, results and measurements) and that does not disclose any specific Service Data (unless in aggregated or de-identified form).</p>
          <p>You hereby authorize watchNXT to collect, store and use your Service Data (and to make use of Site Analytics) in the manner and for the purposes described in these Terms of Service, including without limitation, to:  use Service Data to communicate with you (including by email); access, record, collect, copy, store, process, analyze and use Service Data to provide the Service; develop, improve, extend and test the Service (and underlying technology platforms); deliver and improve television show, movie and other media recommendations provided by watchNXT to you; to design, develop and produce Site Analytics; market and promote watchNXT and the Service using Site Analytics; and disclose, distribute and transmit Site Analytics to third parties.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Forums and User Content</div>
          <p>At this time, watchNXT does not have forums or user supplied content on its Site.  For clarity, watchNXT may use certain user-supplied content from third party social networking websites to provide users with recommendations as part of the Service.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>User Rules and Conduct</div>
          <p>The Service is provided only for your use for informational andpersonal, internal, noncommercial purposes only (the <b>Authorized Use</b>).  Any unauthorized use of the Service (including without limitation, accessing any aspect of the Service for which you are not authorized, or any commercial use by you, such as, for example, reselling or using sample data or other Content) is expressly prohibited.  You are solely responsible for all acts or omissions that occur under your registration.</p>
          <p>As a condition of use, you hereby promise not to use the Service for any purpose that is unlawful, or that is prohibited by the Terms of Service, or for any other purpose not reasonably intended by watchNXT.  You agree to abide by all applicable laws, regulations and rules.  By way of example, and not limitation, you agree not to take any action or provide any Service Data that in any respect:</p>
          <ul className={`${styles.ul}`}>
            <li>is unlawful, deceptive, misleading, fraudulent, or which otherwise violates the Terms of Service;</li>
            <li>reveals any confidential, proprietary or other information that you are required to keep secret;</li>
            <li>imposes an unreasonable or disproportionately large load on watchNXT’s computing, storage or communications systems and networks, or attempts to gain unauthorized access to the Site or other aspect of the Service (including the underlying technology platforms), someone else's registration, Service Data, computer systems or networks connected to the Service, through password mining or otherwise;</li>
            <li>contains software viruses or any other computer codes, files or programs that are designed or intended to disrupt, damage, limit or interfere with the proper function of any software, hardware or network system or to damage or obtain unauthorized access to any system, data or other information of watchNXT;</li>
            <li>registers with watchNXT by any automated means or under false or misleading pretenses, including by the use of a false, misleading or disguised email address;</li>
            <li>harvests, scrapes or collects any information from the Service;</li>
            <li>uses any script, bot or other automated means that only simulates compliance with the Terms of Service or other requirement applicable to the Service;</li>
            <li>disguises the source of Service Data; or</li>
            <li>impersonates any person or entity, including employee or representative of watchNXT.</li>
          </ul>
          <p>watchNXT may, at its sole discretion, immediately suspend or terminate your access to the Service should your conduct fail (or appear to fail) to strictly conform to any provision of this section.</p>
          <p>watchNXT has no obligation to monitor the Service or any user's use thereof.  However, watchNXT reserves the right at any time and without notice to monitor, review, copy, retain or disclose any information as may reasonably be necessary to satisfy any applicable law, regulation, legal process or governmental request or investigation (including any inquiry from law enforcement).</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Connections and Third Party Terms and Conditions</div>
          <p>You may be able to access or communicate with the Service from, and you may be able to link or communicate from the Service to, applications, devices, distribution platforms and websites owned and operated by third parties.  These other applications, devices, platforms and websites are not owned, operated or controlled by watchNXT, and you agree that watchNXT is not responsible for the availability, accuracy, legality, appropriateness or any other aspect of the content or function of such applications, devices, platforms and websites.  Any such connection to or from the Service does not constitute or imply any guarantee or endorsement by watchNXT of that application, device, platform or website or any association with its operators.  Additional or different terms and conditions (including without limitation, privacy and security practices) apply when you access and use such other applications, devices, platforms and websites.</p>
          <p>Without limiting the generality of the foregoing, the Service utilizes the YouTube API Services, Facebook API Services and Instagram API Services.  If your use of the Service invokes the YouTube API Services, then you are also agreeing to be bound by the YouTube Terms of Service, which are located at <a href="https://www.youtube.com/t/terms">https://www.youtube.com/t/terms</a>. If your use of the Service invokes the Facebook API Services or Instagram API Services, then you are also agreeing to be bound by the Facebook Terms of Service, which is located at <a href="https://www.facebook.com/terms.php">https://www.facebook.com/terms.php</a> and at the Instagram website <a href="https://help.instagram.com/478745558852511">https://help.instagram.com/478745558852511</a>.  If your use of the Service invokes the Twitter API Services, then such use shall be subject to the Twitter Privacy Policy, which is located at <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>. </p>
          <p>You acknowledge and agree that the availability of our Chrome Extension is dependent on the third party stores from which you download the Chrome Extension through the Chrome Web Store. The Chrome Web Store may have its own terms and conditions to which you must agree before downloading any extensions from such store. You agree to comply with, and your license to use our Chrome Extension is conditioned upon your compliance with, such Chrome Web Store terms and conditions. To the extent such other terms and conditions from such Web Store are less restrictive than, or otherwise conflict with, the terms and conditions of these Terms of Service, the more restrictive or conflicting terms and conditions in these Terms of Service apply.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Advertising and Promotions</div>
          <p>At this time, the Service does not include advertisements or promotions for or offers from third parties.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Proprietary Rights</div>
          <p>The Service (including the Site, Content and underlying technology platforms) are protected by copyrights, trademarks, service marks, patents, trade secrets or other proprietary rights and laws, and watchNXT (and its applicable affiliates and licensors) shall own and retain all rights, title and interests (including all intellectual property and proprietary rights) therein and thereto.  Unless and only to the extent expressly authorized by watchNXT, you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works of any part of the Service or Content.  However, unless the Service states or implements a different restriction, you may download or display locally watchNXT Data, for a temporary basis. Content for your Authorized Use; provided, that you retain all copyright and other proprietary notices or other attribution contained therein.  Your systematic retrieval of data or other Content from the Service to create or compile, directly or indirectly, a collection, database or directory is strictly prohibited without watchNXT's express prior written permission.</p>
          <p>If you desire to access or use the Service or Content for any purpose other than Authorized Use, then you must first secure permission from watchNXT.</p>
          <p>Reproducing, copying or distributing any Content or any design elements from the Site for any other purpose is strictly prohibited without watchNXT's express prior written permission.  Attempting to access or use the Service or Content for any purpose not expressly permitted in the Terms of Service is prohibited.</p>
          <p>All rights not expressly granted herein are reserved.</p>
          <p>The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, videos, photos, images, illustrations, and so forth (all of the foregoing, for the purposes of this paragraph, the “Third-Party Content”) are protected by copyright and/or other intellectual property laws.  You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Third-Party Content you access through the Services, and you will not use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Third-Party Content not owned by you, without the prior consent of the owner of that Content or in a way that violates someone else’s (including watchNXT’s) rights.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Feedback License</div>
          <p>If you provide watchNXT with any idea, recommendation, suggestion or other feedback regarding possible corrections, changes, improvements or extensions related to the Service or Content or watchNXT’s business (collectively, Feedback), in any form or by any means (whether through the Service or any survey, or by direct communication (e.g., email) with watchNXT, watchNXT or any of its officers, directors, employees or representatives), then you:</p>
          <ul className={`${styles.ul}`}>
            <li>represent and warrant that the Feedback does not include your own or any third party's confidential or proprietary information; and</li>
            <li>acknowledge and agree that watchNXT is under no obligation of confidentiality, express or implied, with respect to the Feedback; and</li>
            <li>agree to grant and do hereby grant to watchNXT, its affiliates, licensees and assigns a non-exclusive, transferable, perpetual, irrevocable, royalty-free, worldwide right and license to use, modify and make derivative works of the Feedback, in any manner, in any media and for any purpose, and to permit third parties to do the same.</li>
          </ul>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Termination</div>
          <p>watchNXT may immediately terminate your access to all or any part of the Service at any time, with or without cause, with or without notice.  You may elect to terminate the Service and your registration at any time by emailing <a href="mailto:support@watchNXT.com">support@watchNXT.com</a> with the Subject Line “Delete Registration.”</p>
          <p>Upon any termination, all rights and obligations of the parties under the Terms of Service shall cease, and you shall immediately stop accessing and using the Service and Content, except that (a) all obligations that accrued prior to the effective date of termination (including payment obligations, if any) and all remedies for breach of the Terms of Service shall survive, (b) watchNXT may retain and use Service Data in the manner described herein, but without disclosing any Service Data unless in aggregated or de-identified form and (c) the provisions of those sections of the Terms of Service that should reasonably be understood to continue in effect shall survive (including without limitation, the sections entitled Service Data and Site Analytics, Proprietary Rights, Feedback License, No Warranties, Limitation of Liability and Indemnification).  After termination, watchNXT has no obligation to maintain any Service Data.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>No Warranties</div>
          <p>Neither watchNXT, Diesel Labs, its affiliates and licensors and its and their officers, directors, employees, contractors and representatives (watchNXT and all such parties together, the “watchNXT Parties”) makes any representations or warranties concerning the Services, including without limitation any Content contained in or accessed through the Services, and the watchNXT Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of or in any way related to your participation in or use of the Services.  The watchNXT Parties make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the Services.  Products and services purchased or offered (whether or not following such recommendations and suggestions) through the Services are provided “AS IS” and without any warranty of any kind from the watchNXT Parties or others (unless, with respect to such others only, provided expressly and unambiguously in writing by a designated third party for a specific product).</p>
          <p>THE SITE, CONTENT AND ALL OTHER ASPECTS OF THE SERVICE ARE PROVIDED "AS IS" AND "AS AVAILABLE".  THE SITE, CONTENT AND ALL OTHER ASPECTS OF THE SERVICE ARE PROVIDED WITHOUT REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES OF ACCURACY, INTEGRATION, TITLE, NON-INFRINGEMENT, QUIET ENJOYMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ALL WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED.  watchNXT, DIESEL LABS AND ITS AFFILIATES, LICENSORS AND BUSINESS PARTNERS DO NOT WARRANT THAT:  (A) ANY INFORMATION WILL BE TIMELY, ACCURATE, RELIABLE OR CORRECT; (B) THE SERVICE WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR PLACE; (C) ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED; (D) THE SERVICE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (E) ANY RESULT OR OUTCOME CAN BE ACHIEVED.  USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.</p>
          <p>SOME STATES AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.</p>
          <p>ALL REPRESENTATIONS AND WARRANTIES REGARDING ANY PROFESSIONAL SERVICES SHALL BE, IF AT ALL, ONLY AS EXPRESSLY AGREED BETWEEN YOU AND THE APPLICABLE PROFESSIONAL.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Limitation of Liability</div>
          <p>You agree that watchNXT and Diesel Labs (and its affiliates and licensors) shall not be responsible or liable for any unauthorized access to, alteration or use of your registration, transmissions or data, or for any material or data sent or received or not sent or received through the Service.</p>
          <p>IN NO EVENT SHALL watchNXT, DIESEL LABS (OR ITS AFFILIATES OR LICENSORS) BE LIABLE CONCERNING ANY SUBJECT MATTER RELATED TO THE SITE, CONTENT OR OTHER ASPECTS OF THE SERVICE, REGARDLESS OF THE FORM OF ANY CLAIM OR ACTION (WHETHER IN CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE), FOR ANY (A) MATTER BEYOND ITS REASONABLE CONTROL, (B) LOSS OR INACCURACY OF DATA, LOSS OR INTERRUPTION OF USE, OR COST OF PROCURING SUBSTITUTE TECHNOLOGIES, GOODS OR SERVICES, (C) INDIRECT, PUNITIVE, INCIDENTAL, RELIANCE, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES INCLUDING, BUT NOT LIMITED TO, LOSS OF BUSINESS, REVENUES, PROFITS OR GOODWILL, OR (D) DAMAGES, IN THE AGGREGATE, IN EXCESS OF AMOUNTS PAID TO watchNXT OR DIESEL LABS PURSUANT TO THESE TERMS OF SERVICE FOR YOUR USE OF THE SERVICE OR US$100.00, WHICHEVER IS GREATER, EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  THESE LIMITATIONS ARE INDEPENDENT FROM ALL OTHER PROVISIONS HEREIN AND SHALL APPLY NOTWITHSTANDING THE FAILURE OF ANY REMEDY PROVIDED IN THESE TERMS OF SERVICE.</p>
          <p>SOME STATES AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Indemnification</div>
          <p>At your cost and expense, you agree to (a) defend watchNXT, Diesel Labs, its affiliates and licensors and its and their officers, directors, employees, contractors and representatives <b>(Indemnitees)</b> against any action or suit by a third party that arises out of your use or misuse of the Service or your breach of any of your representations, warranties or covenants under the Terms of Service and (b) indemnify the Indemnitees for liabilities (resulting from settlements or adverse judgments) and costs and expenses (including without limitation, penalties and reasonable attorneys' fees) related to such claims.  In addition, watchNXT and Diesel Labs reserves the right to assume the exclusive defense and control of any matter subject to the foregoing indemnity, in which event you will assist and cooperate with watchNXT and Diesel Labs in asserting any available defenses.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Governing Law; Arbitration</div>
          <p>A printed version of the Terms of Service and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to the Terms of Service (including any Policy) to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.  You and Diesel Labs agree that any claim or cause of action arising out of or related to the Service must be commenced within 1 year after the claim or cause of action arose.  Otherwise, such claim or cause of action is permanently barred.</p>
          <p>The Terms of Service shall be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts, USA, excluding its conflicts of law rules.  In the event of any conflict between US and foreign laws, regulations and rules, US laws, regulations and rules shall govern.  Neither the United Nations Convention on Contracts for the International Sale of Goods nor any implementation of the Uniform Computer Information Transactions Act in any jurisdiction shall apply under these Terms of Service.</p>
          <p>Except that either party may seek an injunction or other equitable relief from any court of competent jurisdiction, all disputes between the parties arising out of or in relation to or in connection with the Terms of Service shall be settled by binding arbitration in accordance with the JAMS streamlined arbitration rules and procedures then in force, by one neutral arbitrator appointed in accordance with the rules.  The arbitration shall take place in Boston, Massachusetts, USA.  The proceedings shall be in English, all evidence shall be in English (or translated into English) and the governing law shall be as set forth herein.  The arbitrator's decision shall be in writing and shall comply with all terms and conditions in the applicable version of the Terms of Service.  The decision and award rendered shall be final and binding on both parties.  The parties acknowledge and agree that the Terms of Service and any award rendered pursuant hereto shall be governed by the UN Convention on the Recognition and Enforcement of Foreign Arbitral Awards.  Judgment on the award may be entered in any court of competent jurisdiction.</p>
          <p>ANY ARBITRATION UNDER THESE TERMS OF SERVICE WILL TAKE PLACE ONLY ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED.  YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS OF SERVICE, YOU AND DIESEL LABS ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY AND TO PARTICIPATE IN A CLASS ACTION.</p>
          <p>Use of the Service is not authorized in any jurisdiction that does not give effect to all provisions of the Terms of Service, including without limitation, this section.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Integration</div>
          <p>The Terms of Service (including all then current Policies) are the entire agreement between you and watchNXT with respect to access, use and operation of the Service, and supersede all prior or contemporaneous communications, proposals and agreements (whether oral, written or electronic) between you and watchNXT with respect to the Service.  If any provision of the Terms of Service is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms of Service will otherwise remain in full force and effect and enforceable.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>General Provisions</div>
          <p>Your rights and obligations under the Terms of Service are personal to you, and are not assignable, transferable or sublicensable by you except with watchNXT's prior written consent.  watchNXT may subcontract, delegate, assign or otherwise transfer any or all of its rights, licenses and obligations hereunder without your consent.  All waivers, consents and modifications must be in a writing signed by both parties, except as otherwise provided herein.  No agency, partnership, joint venture, or employment relationship is created as a result of the Service or Terms of Service, and neither party has any authority of any kind to bind the other in any respect.  All notices under these Terms of Service will be in writing, in English and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail or express courier, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or email.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Copyright and Trademark Notices</div>
          <p>The Terms of Service, the Site and all Content provided by watchNXT or powered by Diesel Labs are copyright © 2018-2019 Diesel Labs, Inc. and/or its licensors or suppliers.</p>
          <p>watchNXT™ is the trademark of Diesel Labs.  The names and logos of companies, products or services mentioned in the Terms of Service, at the Site or in any Content are be the trademarks or service marks of their respective owners, and shall not be deemed to create any endorsement or association between  and such owners.</p>
        </Col>
        <Col lg={12} className="mb-5">
          <div className={`${styles.subTitle}`}>Contact</div>
          <p>If you have any questions regarding the Terms of Service or your dealings with the Service, please contact us at:</p>
          <p><a href="mailto:support@watchNXT.com">support@watchNXT.com</a></p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}
