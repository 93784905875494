/*
 * Google Analytics Tracking
 */
const GA_TRACKING_ID = 'UA-159861426-1';

export function trackGA (page, location) {
  window.gtag('config', GA_TRACKING_ID, {
    page_title: page,
    page_path: location.pathname
  });
}
