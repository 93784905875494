import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Container, Row, Col } from 'react-bootstrap';
import aboutHeaderimage from '../../assets/images/TVGifFinal5.gif';
import AppSmallerer from '../../assets/images/AppSmallerer.gif';
import relevancy from '../../assets/images/relevancy.jpg';
import onePlace from '../../assets/images/onePlace.jpg';
import todoList from '../../assets/images/todoList.jpg';
import privacy from '../../assets/images/private.jpg';
import noHassles from '../../assets/images/noHassles.jpg';
import history from '../../assets/images/history.jpg';

import styles from './About.module.css';

export default function About() {
  return (
    <div>
      <Container fluid={true} className={`${styles.about}`}>
        <Header/>
      </Container>
      <Container fluid={false} className={`${styles.about}`}>
        <Row>
          <Col lg={12} className={`${styles.headerImage} d-flex align-items-center justify-content-md-center mb-4`}>
            <img src={aboutHeaderimage} alt="aboutHeaderimage" />
          </Col>
          <Col lg={12} className={`${styles.ContentArea} d-flex align-items-center justify-content-md-center`}>
            <div className={`${styles.headerText}`}>What will you watch next?</div>
            <div>Discover personalized media recommendations across all content</div>
            <a href="https://chrome.google.com/webstore/detail/watchnxt/hhafdfiaaajljeoeocmjhkgkeknpenhm" className={`${styles.btnRedirect}`} data-initialized="true">add to Chrome!</a>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mt-3 mb-3">
            <hr className={`${styles.hrClr}`} />
          </Col>
        </Row>
        <Row>
          <Col lg={12} className={`${styles.baseTitle} d-flex align-items-center justify-content-md-center flex-column`}>
            <div>One-click access to media recommendations just for you</div>
          </Col>
          <Col lg={12} className="mb-4 text-center">
            <img src={AppSmallerer} alt="aboutHeaderimage" />
          </Col>
          <Col lg={12} className="text-center">
            <div>Head over to any of your favorite streaming services or YouTube and get recommendations right in your browser!</div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mt-3 mb-3">
            <hr className={`${styles.hrClr}`} />
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mt-2 mb-3 text-center d-flex align-items-center justify-content-md-center">
            <div className={`${styles.headerText} ${styles.SectionBorder}`}>watchNXT Features</div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={4} className="mt-5 mb-3 text-center d-flex align-items-center justify-content-md-start flex-column">
            <div className={`${styles.baseTitle} mb-0`}>Keep a Media To Do List</div>
            <img src={todoList} alt="TODO List" width="250px" />
            <div className="mt-3">Keep track of what's next in your queue with your Watch List</div>
          </Col>
          <Col md={4} className="mt-5 mb-3 text-center d-flex align-items-center justify-content-md-start flex-column">
            <div className={`${styles.baseTitle} mb-0`}>All Platforms in One Place</div>
            <img src={onePlace} alt="One Place" width="250px" />
            <div className="mt-3">Netfilx, Hulu, Amazon Prime and Youtube recommendations living in one place.</div>
          </Col>
          <Col md={4} className="mt-5 mb-3 text-center d-flex align-items-center justify-content-md-start flex-column">
            <div className={`${styles.baseTitle} mb-0`}>No Hassles</div>
            <img src={noHassles} alt="No Hassles" width="250px" />
            <div className="mt-3">No need to fill in surveys or user registration recommendations are based on what you're watching right now!</div>
          </Col>
          <Col md={4} className="mt-5 mb-3 text-center d-flex align-items-center justify-content-md-start flex-column">
            <div className={`${styles.baseTitle} mb-0`}>Guaranteed Relevancy</div>
            <img src={relevancy} alt="Relevancy" width="250px" />
            <div className="mt-3">Our algorithm makes tailored recommendations just for you based on actual viewership and social engagement signals from milions of people</div>
          </Col>
          <Col md={4} className="mt-5 mb-3 text-center d-flex align-items-center justify-content-md-start flex-column">
            <div className={`${styles.baseTitle} mb-0`}>Review Your Viewving History</div>
            <img src={history} alt="ScreenshotsForCHistoryhrome1" width="250px" />
            <div className="mt-3">Keep track of what you've watched on the Watch History tab.</div>
          </Col>
          <Col md={4} className="mt-5 mb-3 text-center d-flex align-items-center justify-content-md-start flex-column">
            <div className={`${styles.baseTitle} mb-0`}>100% Private</div>
            <img src={privacy} alt="Private" width="250px" />
            <div className="mt-3">We're 100% anonymous: no usernames, no email addresses. So feel comfortable watching watever, whenever</div>
          </Col>
        </Row>
        <Footer/>
      </Container>
    </div>
  );
}
