import * as ActionTypes from '../constants/action-types';
// import Config from '../classes/Config';
// import { CALL_API } from '../middleware/api';
import auth0WebAuth from './auth0_lock';

// -----------------------------------------------------------------------------
// Action Types

function logoutAction () {
  return { type: ActionTypes.LOGOUT };
}

function requestLogin () {
  return { type: ActionTypes.REQUEST_LOGIN };
}

function receiveLogin (accessToken, idToken, idTokenPayload) {
  return {
    type: ActionTypes.RECEIVE_LOGIN,
    accessToken,
    idToken,
    idTokenPayload
  };
}

function requestProfile () {
  return { type: ActionTypes.REQUEST_PROFILE };
}

function receiveProfile (profile) {
  return {
    type: ActionTypes.RECEIVE_PROFILE,
    profile
  };
}

function receiveProfileError (error) {
  return {
    type: ActionTypes.RECEIVE_PROFILE_FAILURE,
    error
  };
}

function storePostLoginRedirect (redirect) {
  return {
    type: ActionTypes.STORE_POST_LOGIN_REDIRECT,
    redirect
  };
}

// function requestUpdateProfile () {
//   return { type: ActionTypes.REQUEST_UPDATE_PROFILE };
// }

// -----------------------------------------------------------------------------
// User invokable functions

/** Initialize lock with callbacks to complete the login process.
 *
 */
export function initializeLock (dispatch) {
  auth0WebAuth.parseHash(function (err, authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      dispatch(receiveLogin(authResult.accessToken, authResult.idToken, authResult.idTokenPayload));
      dispatch(requestProfile());
      auth0WebAuth.client.userInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          return dispatch(receiveProfileError(error));
        } else {
          return dispatch(receiveProfile(profile));
        }
      });
    } else if (err) {
      // TODO: Handle this a bit better?
      console.log(err);
    }
  });
}

export function savePostLoginRedirect (redirect) {
  return dispatch => {
    return dispatch(storePostLoginRedirect(redirect));
  };
}

export function loginIfNeeded () {
  return (dispatch, getState) => {
    const user = getState().user;
    console.log('user auth0 authorize', user);
    // if (user.isLoggedIn) {
    //   return Promise.resolve();
    // } else {
    //   dispatch(requestLogin());
    //   // Only some values of prompt work here and force the prompt for a username/password. For
    //   // instance { prompt: "consent" } does seem to work, but it causes the prompt to require
    //   // consent for API and profile access.
    //   return Promise.resolve(auth0WebAuth.authorize());
    // }
    dispatch(requestLogin());
    return Promise.resolve(auth0WebAuth.authorize());
  };
}

export function logout () {
  return (dispatch) => {
    return dispatch(logoutAction());
    // TODO: Figure out how to incoporate Auth0 logout functionality here.
    // https://auth0.com/docs/libraries/auth0js/v9#logout
  };
}

/** Set the access token to be used as an override for user-auth
 *
 *  We use this for shortcut authentication where the user is technically un-authenticated but we
 *  would still like to provide some kind of authentication.
 */
export function setOverrideAccessToken (token) {
  return (dispatch, getState) => {
    if (getState().user.overrideAccessToken !== token) {
      return dispatch({
        type: ActionTypes.SET_OVERRIDE_ACCESS_TOKEN,
        token
      });
    } else {
      return Promise.resolve();
    }
  };
}
