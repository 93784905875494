import { NEW_REQUEST,
  NEW_SUCCESS,
  NEW_FAILURE } from '../constants/action-types';
import { DateOnly } from '../utils';

const initialState = {
  loading: false,
  error: null,
  details: null,
  session: null
};
function newReducer(state = initialState, action) {
  switch (action.type) {
  case NEW_REQUEST:
    return {
      ...state,
      loading: true
    };
  case NEW_SUCCESS:
    return {
      ...state,
      loading: false,
      details: sortData(action.payload),
      session: DateOnly()
    };
  case NEW_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload
    };
  default:
    return state;
  }
}

export default newReducer;

function sortData(data) {
  const sortedEntries = {};
  const sortedKeys = Object.keys(data).sort((a, b) => new Date(a) - new Date(b));
  sortedKeys.forEach(sortedKey => sortedEntries[sortedKey] = data[sortedKey]);
  return sortedEntries;
}
