import React, {useRef,useEffect} from 'react';
import { Container, Row, Col, Form, ButtonToolbar, Button, ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

import styles from './HomeComponent.module.css';
import search from '../assets/images/search.png';
import logo from '../assets/images/logo.png';
import show from '../assets/images/show.png';
import Footer from './common/Footer';
import { DateString } from '../utils';

HomeComponent.propTypes = {
  loading: PropTypes.bool,
  searchResults: PropTypes.array,
  getSearchData: PropTypes.func,
  searchResultClear: PropTypes.func
};

export default function HomeComponent({ searchResults, getSearchData, searchResultClear}) {
  const inputRef = useRef(null);
  const ref = useRef(null);
  const currentDateStr = DateString(new Date());
  // Init a timeout variable to be used below
  let timeout = null;
  useEffect(() => {
    if (inputRef.current) {
      // Listen for keystroke events
      inputRef.current.addEventListener('keyup', function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          getSearchData(inputRef.current.value);
        }, 1000);
      });
    }
  }, []);
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      searchResults && searchResultClear();
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  return (
    <Container fluid={true} className={`${styles.wrapper} search-wrapper justify-content-around`}>
      <Row className="flex-column">
        <Col md="auto" className={`${styles.main} searchMain text-center`}>
          <img className={`${styles.logo} logo`} src={logo} alt="logo" />
          <Form className={searchResults ? 'formActive':''} ref={ref}>
            <Form.Group controlId="formBasicEmail" className="position-relative mb-0 d-flex justify-content-center">
              {/* {loading
                ? <div className={`${styles.Loading} spinner-grow`} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                : <img src={search} alt="search" className={styles.searchIcon} />
              } */}
              <img src={search} alt="search" className={`${styles.searchIcon} searchIcon`} ></img>
              <Form.Control
                type="text"
                placeholder="enter the name of your favorite show or movie"
                className={`${styles._searchInput} form-control _searchInput`}
                // onChange={getSearchData}
                onClick={(val) => getSearchData(val.target.value)}
                ref={inputRef}
                autoComplete={'off'}
              />
              <span className={`${styles.baseLine} baseLine`}/>
            </Form.Group>
            <ResultList
              // loading={loading}
              searchResults={searchResults}
              searchResultClear={searchResultClear}
            />
          </Form>
        </Col>
        <Col>
          <ButtonToolbar className="d-flex justify-content-center mt-5 labelGrouph">
            <Button variant="outline-primary" className={styles.labelBtn}>
              <Link className={styles.cwhite} to={`/whatsnew/${currentDateStr}`}>what's new</Link>
            </Button>
            <Button variant="outline-primary" className={styles.labelBtn}>
              <Link className={styles.cwhite} to='/trending'>trending</Link>
            </Button>
            <Button variant="outline-primary" className={styles.labelBtn}>
              <Link className={styles.cwhite} to='/showmesomething'>show me something</Link>
            </Button>
          </ButtonToolbar>
        </Col>
        <Footer />
      </Row>
    </Container>
  );
}

ResultList.propTypes = {
  searchResults: PropTypes.array,
  searchResultClear: PropTypes.func
};

function ResultList({searchResults, searchResultClear}) {
  const history = useHistory();

  function RedirectRecoRoute(topicId) {
    history.push(`recommendations/${topicId}`);
    searchResultClear();
  }

  return (
    <Col className={`${styles.searchDropdown} searchDropdown bg-white pl-0 pr-0`}>
      <ListGroup>
        {searchResults && searchResults.length === 0 &&
          <ListGroup.Item>
            <span>{'No results found'}</span>
          </ListGroup.Item>
        }
        {searchResults && searchResults.map(searchResult => {
          const { id, thumbnail, name, display_name, entity_type, channel } = searchResult;
          const thumb = thumbnail ? thumbnail : show;
          return (<ListGroup.Item
            key={id}
            className='border-left-0 border-top-0 border-right-0 pb-1 pt-1 d-flex align-items-center'
            onClick={() => RedirectRecoRoute(id)}
          >
            <span className={styles.imgWrap}>
              <img className={styles.listImg} src={thumb} alt="infoImage"
                onError={(e)=>{e.target.onerror = null; e.target.src = show;}} />
            </span>

            <span
              className={`${styles.listlabel} listlabel`}
            >
              <EllipsisWithTooltip placement="bottom">{display_name || name}</EllipsisWithTooltip>
            </span>

            <span className={`${styles.listInfo} listInfo`}><EllipsisWithTooltip placement="bottom">{channel || ''}</EllipsisWithTooltip></span>
            <span className={`${styles.listGroup} listGroup`}><EllipsisWithTooltip placement="bottom">{entity_type}</EllipsisWithTooltip></span>
          </ListGroup.Item>);
        })}
      </ListGroup>
    </Col>
  );
}
