import PropTypes from 'prop-types';

/* Extension on a Javascript object to wrap the profile JSON returned from Auth0
 *
 * We use this to add methods, etc onto the profile object so we can as things of it on pages such
 * as which groups a user is in, etc.
 */

export default class Profile {
  static PropTypes = PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(PropTypes.string).isRequired
  })

  constructor (data = {}) {
    // data will be json. Extend ourselves with it.
    Object.assign(this, data);
    // user_id and groups are our own property names. They come from Auth0 under different names.
    if (!this.user_id && data) {
      this.user_id = data.sub;
    }
    if (!this.groups && data) {
      this.groups = data['https://diesellabs.com/groups'];
    }
  }

  get isDefined () {
    return !!this.user_id;
  }

  get isAdmin () {
    return this.groups && this.groups.includes('Admin');
  }

  get isDieselInternal () {
    return this.groups && this.groups.includes('Diesel Internal');
  }

  get filteredGroups () {
    return (this.groups && this.groups.filter(g => !['Admin', 'Diesel Internal'].includes(g))) || [];
  }
}
