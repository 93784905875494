import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import { getSearchData, searchResultClear } from '../actions';
import HomeComponent from '../components/HomeComponent';
import { trackGA } from '../utils';
import { HOME } from '../constants/modules';

Home.propTypes = {
  loading: PropTypes.bool,
  searchResults: PropTypes.array,
  getSearchData: PropTypes.func,
  searchResultClear: PropTypes.func
};

function Home({loading, searchResults, getSearchData, searchResultClear}) {
  const location = useLocation();
  useEffect(() => {
    trackGA(HOME, location);
  }, [location]);

  return (
    <HomeComponent
      loading={loading}
      searchResults={searchResults}
      getSearchData={getSearchData}
      searchResultClear={searchResultClear}
    />
  );
}

const mapStateToProps = state => {
  const { homeReducer } = state;
  return {
    searchResults: homeReducer.searchResults,
    loading: homeReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSearchData: (val) => dispatch(getSearchData(val)),
    searchResultClear: () => dispatch(searchResultClear())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
