import { combineReducers } from 'redux';

import homeReducer from './homeReducer';
import recommendationsReducer from './recommendationsReducer';
import newReducer from './newReducer';
import trendingReducer from './trendingReducer';
import user from './auth_reducer';

const rootReducer = combineReducers({
  homeReducer,
  recommendationsReducer,
  newReducer,
  trendingReducer,
  user
});

export default rootReducer;
