import { SEARCH_RESULT_REQUEST,
  SEARCH_RESULT_SUCCESS,
  SEARCH_RESULT_FAILURE,
  SEARCH_RESULT_CLEAR } from '../constants/action-types';

const initialState = {
  loading: false,
  query: '',
  error: null,
  searchResults: null
};
function homeReducer(state = initialState, action) {
  switch (action.type) {
  case SEARCH_RESULT_REQUEST:
    return {
      ...state,
      query: action.payload,
      loading: true
    };
  case SEARCH_RESULT_SUCCESS:
    return {
      ...state,
      loading: false,
      searchResults: action.payload.results
    };
  case SEARCH_RESULT_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload
    };
  case SEARCH_RESULT_CLEAR:
    return {
      ...state,
      ...initialState
    };
  default:
    return state;
  }
}

export default homeReducer;
