export function PlatformSearchLink(filter, search='', platformLinks) {
  switch(filter){
  case 'netflix':
    return `https://www.netflix.com/search?q=${encodeURIComponent(search)}`;
  case 'amazon':
    return `https://www.amazon.com/s?k=${encodeURIComponent(search)}&i=instant-video`;
  case 'hulu':
    return 'https://www.hulu.com/search/';
  case 'channel':
    if(platformLinks && platformLinks.Channel && platformLinks.Channel.platformId)
      return `https://www.youtube.com/channel/${platformLinks.Channel.platformId}`;
    else
      return `https://www.youtube.com/results?search_query=${encodeURIComponent(search)}`;
  case 'video':
    if(platformLinks.Video && platformLinks.Video.platformId)
      return `https://www.youtube.com/watch?v=${platformLinks.Video.platformId}`;
    else
      return `https://www.youtube.com/results?search_query=${encodeURIComponent(search)}`;
  case 'appleTv':
    return 'https://tv.apple.com/?l=en';
  case 'disney+':
    return 'https://www.disneyplus.com/search';
  default:
    return null;
  }
}
