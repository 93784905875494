import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Header from './Header';
import Footer from './Footer';

import styles from './PrivacyPolicy.module.css';

export default function PrivacyPolicy() {
  return (
    <Container fluid={true} className={`${styles.Privacy} privacy`}>
      <Header />
      <Row>
        <Col lg={12} className={`${styles.baseTitle} d-flex align-items-center`}>
          <span>Privacy Policy</span>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Service</div>
          <p>watchNXT’s website watchNXT.com (the <b>Site</b>) is powered by Diesel Labs, Inc. <b>(Diesel Labs)</b>.  The Site,
          together with all of the data <b>(watchNXT Data)</b>, functionalities, materials, documents, text, images, graphics, animation,
          videos and other information and content (collectively, <b>Content</b>) and any and all services, watchNXT products (including,
          but not limited to, the Chrome Extension), applications, and other web or browser extensions provided at the Site or
          otherwise provided by watchNXT, are collectively referred to as the <b>Service</b>.</p>
          <p>The following policy explains how watchNXT collects, uses and disseminates information obtained from or about you as
          a user of the Service.  Capitalized terms used herein without definition will have the same meanings as defined in the
          <b> Terms of Service</b> that are applicable to the Service, which are available at <a href="https://www.watchNXT.com/terms-of-service">https://www.watchNXT.com/terms-of-service</a>.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Information Collected</div>
          <p>When you visit the Site or otherwise access the Service, web servers collect general information about your visit
            (such as, for example, traffic data like time, date and the address of the website from which you entered the Site),
             which is stored as anonymous, aggregate data.  watchNXT also collects more specific information about your use of the
             Service (such as, for example, demographic data, frequency and duration of use).  Collecting such data (collectively, <b>Traffic Data</b>) may entail the use of software programs (such as Google Analytics), cookies, IP addresses or other numeric
             codes used to identify a computer. You may choose to use certain features of the Service, such as web extensions or apps,
             that permit watchNXT to provide you with television show, movie or other media recommendations based on what you view or
             stream through a third party streaming service or app. In order to provide and improve such recommendations, watchNXT also
             collects more specific information such as, television shows, movies or other media that you view or stream, have viewed or
             stream (i.e., your viewing or streaming history), or that you have bookmarked or saved to view or stream later, time spent
             viewing or streaming such shows or other media (for clarity, this includes both time spent viewing or streaming a specific
             show or other media and time spent viewing or streaming shows or other media in the aggregate), and the third party websites,
             streaming services or apps that you use. </p>
          <p>You may elect to register with watchNXT to access the Service, at your sole discretion.  If you choose to register with watchNXT, then you will be required to provide at least an email address in order to establish your registration.  You are required to maintain and update your registration data from time to time, to ensure that it is current, complete and accurate. </p>
          <p>Whenever you interact with the Service, a variety of technologies are employed to automatically or passively collect <b>Service Data</b>.  The term Service Data means all data and information that are provided by you during registration and subsequent use of the Service, and all other data and information about you that are otherwise discerned or collected by watchNXT based on your access and use of the Service.  For clarity, Service Data includes, but is not limited to:  Personal Data; browser type; device type; device identifiers; operating system; time of day; identification of Site page views; use of particular Service features; IP address; identification of television shows, movies or other media viewed or streamed; time spent viewing or streaming television shows, movies or other media; and identification of third party websites, streaming services or apps. </p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Children</div>
          <p>As noted in the Terms of Service, Diesel Labs will not collect personally identifiable information from any person who is actually known to us to be under the age of 13.  If we become aware that a person under 13 has provided personally identifiable information, Diesel Labs will take steps to remove such information and terminate that individual's registration, access and use of the Service.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Use of Information</div>
          <p>Except as set forth below, at no time will any of your Personal Data be shared with any third party by watchNXT.  Your IP address is not linked to personally identifiable information, but is used to gather broad demographic data and to monitor statistics to improve the Service or provide you with television show, movie or other media recommendations.</p>
          <p>watchNXT uses Traffic Data to help diagnose problems with its web servers, to administer the Service and to analyze user trends and behaviors.  watchNXT will share Traffic Data with certain third parties for the purposes of technical and customer support.</p>
          <p>watchNXT collects, stores, processes and analyzes Service Data and produces <b>Site Analytics</b>.  The term Site Analytics means statistics, metrics, abstractions and other analyses that are based on or derived from the Service, Service Data and/or your use of the Service (including without limitation, measurements of Service usage and performance), which are developed in a manner that does not disclose the identity of any user (such as, in the aggregate with other data, results and measurements) and that does not disclose any specific Service Data (unless in aggregated or de-identified form).</p>
          <p>watchNXT will collect, store and use your Service Data (and exploit make use of Site Analytics) in the manner and for the purposes described in the Terms of Service, including without limitation, to:  use Service Data to communicate with you (including by email); access, record, collect, copy, store, process, analyze and use Service Data to provide the Service; develop, improve, extend and test the Service (and underlying technology platforms); deliver and improve television show, movie or other media recommendations provided by watchNXT to you; to design, develop and produce Site Analytics; market and promote watchNXT and the Service using Site Analytics; and disclose, distribute and transmit Site Analytics to third parties.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>IP Addresses, Cookies and Web Beacons</div>
          <p>IP Address.  watchNXT uses your IP address to help diagnose problems with its web servers and administer the Service.  Your IP address is used to help identify you and to gather demographic information.  For example, we may derive your general geographic location (city, state or metropolitan region) based on your IP address.  IP addresses are also used to provide an audit trail regarding use of the Service.</p>
          <p>Cookies.  Cookies are small files that are stored on your computer, smartphone or other device by your web browser.  Cookies in and of themselves do not personally identify users, although they do identify a user's computer. </p>
          <p>At this time, the only cookies we use are "session cookies" and we do not permit third parties to drop their cookies on your device.  As the name suggests, session cookies help us provide the Service only during the current session.  Session cookies are transient, existing only in temporary memory while currently using the Service.  Session cookies are deleted when you close the browser.</p>
          <p>On most web browsers, you will find a "help" section on the toolbar. Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to refuse some or all cookies, or to delete existing cookies from your device. For details on how to control or delete cookies, see https://aboutcookies.org.</p>
          <p>Google Analytics. We use Google Analytics, a web analysis service provided by Google Inc. ("Google") which is based on cookies technologies. The information generated by the cookie is usually sent to a Google server in the USA, where it is stored. On behalf of watchNXT, Google will use the generated information to evaluate your use of the Site, to compile reports on Site activities, and to provide the Site operator with additional services connected with Site and Internet use. The IP address transmitted by your browser in connection with Google Analytics is not collated with other data by Google. To opt out of being tracked by Google Analytics across all websites visit http://tools.google.com/dlpage/gaoptout.</p>
          <p>Web Beacons.  A web beacon (which is also known as a "clear gif" or "pixel tag") is an electronic file that usually consists of a single-pixel image.  It can be embedded in a web page or in an email to transmit information, which could include Personal Data.  For example, it could allow an email sender to determine whether a user has opened a particular email.</p>
          <p>At this time, watchNXT does not use web beacons.</p>
          <p>Sharing What You Watch.  With your consent, you may choose to automatically communicate or allow watchNXT to access information about the television shows, movies, videos or other media that you view (e.g., show titles, episode names) on third party websites and streaming services such as, by way of illustration, Netflix, Hulu, with watchNXT through your use of services provided by such third parties (“Watching Information”). This information may help watchNXT personalize your experience by providing you with more tailored content and recommendations. You may view or change your Watching Information consent preferences at any time by accessing your Watch History Tab. For the avoidance of doubt, even if you do not choose to share your Watching Information, if you use the Services, i.e., you select a television show that watchNXT has recommended to you, watchNXT will use that data, without linking it to your personally identifiable information, in order to improve the Services.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Behavioral (Targeted) Advertising</div>
          <p>Online behavioral (or targeted) advertising refers to a broad set of activities companies engage in to collect information about your online activity (like website pages you visit) and use it to show you ads or content they believe to be more relevant to you.  To accomplish this, companies use a variety of tools to collect information, typically including cookies, web beacons and tracking pixels.</p>
          <p>At this time, watchNXT does not engage in behavioral or targeted advertising.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Data Integrity</div>
          <p>watchNXT processes Personal Data only for the purposes for which it was collected and in accordance with this Privacy Policy or any applicable Service-specific privacy notice.  We periodically review our data collection, storage and processing practices to ensure that we only collect, store and process the Personal Data needed to provide or improve our Service.  We take reasonable steps to ensure that the Personal Data we process is accurate, complete, and current, but we depend on you to update or correct your Personal Data whenever necessary.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Do Not Track Policy</div>
          <p>Your browser may offer you a "Do Not Track" option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities, over time and across different websites.  Do Not Track signals are set on a browser-by-browser basis, so you must set them on every browser you use if you do not wish to be tracked.  Moreover, watchNXT may continue to collect information about your use of the Site in the manner described in this Privacy Policy.</p>
          <p>For clarity, watchNXT cannot and does not make any promises about how third parties react when you set Do Not Track signals on your browser.  In addition, the Do Not Track signal does not impact the use of information collected prior to such request.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Sharing of Personal Data</div>
          <p>Currently, watchNXT will not share your Personal Data with any third party for purposes that are not related to providing the Service to you.  In addition, watchNXT may disclose Personal Data (a) as reasonably necessary for watchNXT (or its service providers) to operate the Service, including processing and responding to your inputs, (b) to communicate with you, (c) as otherwise permitted under the Privacy Policy or elsewhere in the Terms of Service, (d) when watchNXT reasonably believes that such action is necessary to conform or comply with any legal, regulatory, law enforcement or similar requirement or investigation, to protect or defend the rights or property of watchNXT or any third party or to enforce the Terms of Service or (e) as otherwise authorized by you.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>California Residents</div>
          <p>Under California Civil Code Section 1798.83 (the "Shine the Light" law), California residents who provide personal information in obtaining products or services from watchNXT are entitled to request and obtain from us once a calendar year information about the customer information we shared, if any, with other businesses for their own direct marketing uses.  If applicable, this information would include the categories of customer information and the names and addresses of those businesses with which we shared customer information for the immediately prior calendar year (e.g., requests made in 2017 will receive information regarding 2016 sharing activities).  If you are a California resident and would like a copy of this information, please submit a written request to:</p>
          <p><a href="mailto:support@watchNXT.com">support@watchNXT.com</a></p>
          <p>Subject Line:  California/Shine the Light</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Business Transfers</div>
          <p>watchNXT may transfer Traffic Data, Service Data (including Personal Data) and Site Analytics to any successor to all or substantially all of its business or assets related to the Service.  In the event of any such transfer, the successor will have all of the rights and be subject to all of the obligations of this Privacy Policy, including, without limitation, the right to modify or replace this Privacy Policy, as provided herein.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Security</div>
          <p>Information collected by watchNXT is stored in operating environments that are secured using commercially available tools and procedures, and which are not made generally available to the public.  Unfortunately, no data transmission over the Internet and/or wireless networks can be guaranteed to be 100% secure.  As a result, watchNXT cannot unequivocally guarantee the security of any information you provide, and you do so at your own risk.  Once watchNXT receives your information, we will make reasonable efforts to ensure its security on our systems.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Connections and Third Party Terms and Conditions</div>
          <p>You may be able to access or communicate with the Service from, and you may be able to link or communicate from the Service to, applications, devices, distribution platforms and websites owned and operated by third parties. These other applications, devices, platforms and websites are not owned, operated or controlled by watchNXT and the policies and procedures described in this Privacy Policy will not apply.  Additional or different terms and conditions (including with respect to privacy and security practices) will apply when you access and use such other applications, devices, platforms and websites, which are not the responsibility of watchNXT.</p>
          <p>Without limiting the generality of the foregoing, the Service utilizes the YouTube API Services, Facebook API Services, and Instagram API Services, and Twitter API Services.  If your use of the Service invokes the YouTube API Services, then such use shall be subject to the Google Privacy Policy, which is located at <a href="http://www.google.com/policies/privacy">http://www.google.com/policies/privacy</a>. If your use of the Service invokes the Facebook API Services or Instagram API Services, then you are also agreeing to be bound by the Facebook Data Policy, which is located at <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a> and the Instagram Privacy Policy, which is located at <a href="https://help.instagram.com/402411646841720">https://help.instagram.com/402411646841720</a>.  If your use of the Service invokes the Twitter API Services, then such use shall be subject to the Twitter Privacy Policy, which is located at <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Opt-Out</div>
          <p>Please be aware that you will always receive certain emails from us related to the proper functioning of your registration or changes in the Terms of Service or this Privacy Policy.  We also may send you promotional emails unless you have opted-out from receiving them.  If you wish to opt-out of receiving promotional emails at any time, please click the "unsubscribe" link at the bottom of the email.</p>
          <p>Your registration can only be terminated by following the process described in the Terms of Service.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Termination</div>
          <p>If you terminate your registration for the Service, or if your registration is terminated for any reason by watchNXT, then watchNXT will remove your Service Data from the Service; provided, watchNXT may retain and use your Service Data in the manner described herein, but only in aggregated or de-identified form.</p>
        </Col>
        <Col lg={12}>
          <div className={`${styles.subTitle}`}>Updates</div>
          <p>watchNXT reserves the right, at its sole discretion, to update, modify or replace this Privacy Policy, in whole or in part, at any time.  watchNXT will use reasonable efforts to notify you of any material change in advance of the effective date of any change.  Change notices may be communicated by postings at the Site, by email or otherwise.  In any case, you should periodically check this Privacy Policy for changes.  Continued access or use of the Service following any change to this Privacy Policy constitutes your acceptance of those changes.  The Privacy Policy may not otherwise be amended, as it applies to you, except by a written agreement executed by you and watchNXT.</p>
          <p>Information collected by the Service is subject to the Privacy Policy in effect at the time of use.</p>
          <p>You may correct or otherwise change your registration data by contacting watchNXT, as provided below.</p>
        </Col>
        <Col lg={12} className="mb-5">
          <div className={`${styles.subTitle}`}>Contact</div>
          <p>If you have any questions regarding this Privacy Policy or your dealings with the Service, please contact us at:</p>
          <p><a href="mailto:support@watchNXT.com">support@watchNXT.com</a></p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}
