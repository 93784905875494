import api from '../middleware/api';
import { SEARCH_RESULT_REQUEST,
  SEARCH_RESULT_SUCCESS,
  SEARCH_RESULT_FAILURE,
  SEARCH_RESULT_CLEAR } from '../constants/action-types';

export function getSearchData(val) {
  // const query = val.target.value;
  return (dispatch, getState) => {
    if(val.length === 0) {
      dispatch(searchResultClear());
    }
    if(getState().query !== val && val.length !== 0) {
      dispatch(searchResultRequest(val));
      const data = {
        keyword: val
      };
      api.fetchPost('search', data)
        .then(({ data }) => {
          dispatch(searchResultSuccess(data));
        })
        .catch(() => {
          dispatch(searchResultFailure('error'));
        });
    }
  };
}

// action creators
function searchResultRequest(payload) {
  return { type: SEARCH_RESULT_REQUEST, payload };
}
function searchResultSuccess(payload) {
  return { type: SEARCH_RESULT_SUCCESS, payload };
}
function searchResultFailure(payload) {
  return { type: SEARCH_RESULT_FAILURE, payload };
}
export function searchResultClear() {
  return { type: SEARCH_RESULT_CLEAR };
}
