import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import { getRandomRecos, modifyFilters } from '../actions';
import RecommendationsComponent from '../components/RecommendationsComponent';
import { trackGA } from '../utils';
import { SHOW_ME_SOMETHING } from '../constants/modules';

Recommendations.propTypes = {
  recosObj: PropTypes.object,
  getRandomRecos: PropTypes.func,
  modifyFilters: PropTypes.func
};

function Recommendations({recosObj, getRandomRecos, modifyFilters}) {
  const location = useLocation();
  useEffect(() => {
    trackGA(SHOW_ME_SOMETHING, location);
  }, [location]);
  useEffect(() => getRandomRecos(), []);
  const recos = recosObj.recos && recosObj.recos[recosObj.topicId];
  return (
    <RecommendationsComponent
      recosObj={{ ...recosObj, recos }}
      modifyFilters={modifyFilters}
    />
  );
}

const mapStateToProps = state => {
  const { recommendationsReducer } = state;
  return {
    recosObj: recommendationsReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRandomRecos: () => dispatch(getRandomRecos()),
    modifyFilters: (val, filter) => dispatch(modifyFilters(val, filter))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
