/* Diesel config, all values set from generated env file
 *
 */
class DieselConfig {

  get Auth0ClientId () {
    return process.env.REACT_APP_DIESEL_AUTH0_CLIENT_ID;
  }

  get Auth0Domain () {
    return process.env.REACT_APP_DIESEL_AUTH0_DOMAIN;
  }
}

const Config = new DieselConfig();

export default Config;
