import axios from 'axios';

const preUrl = process.env.REACT_APP_API_URL;

export default {
  fetchPost: (url, data) => axios.post(`${preUrl}${url}`, data),
  fetchGet: (url) => axios.get(`${preUrl}${url}`),
  fetchLocalGet: (url) => axios.get(url, {responseType: 'json'}),
  fetchPut: (url, data) => axios.put(`${preUrl}${url}`, data)
};
