import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';

import { getRecos } from '../actions';
import RecommendationsComponent from '../components/RecommendationsComponent';
import { trackGA } from '../utils';
import { RECOMMENDATION } from '../constants/modules';

Recommendations.propTypes = {
  recosObj: PropTypes.object,
  getRecos: PropTypes.func
};
function Recommendations({recosObj, getRecos}) {
  const location = useLocation();
  useEffect(() => {
    trackGA(RECOMMENDATION, location);
  }, [location]);
  const { topicId } = useParams();
  useEffect(() => getRecos(topicId), [topicId]);
  const recos = recosObj.recos && recosObj.recos[topicId];
  return (
    <RecommendationsComponent
      recosObj={{ ...recosObj, recos }}
    />
  );
}

const mapStateToProps = state => {
  const { recommendationsReducer } = state;
  return {
    recosObj: recommendationsReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecos: (val) => dispatch(getRecos(val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
