import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

LoginComponent.propTypes = {
  loading: PropTypes.bool,
  searchResults: PropTypes.array,
  getSearchData: PropTypes.func,
  searchResultClear: PropTypes.func,
  login: PropTypes.func
};

export default function LoginComponent({login}) {
  const div = {
    maxWidth: 330,
    padding: 15,
    margin: '0 auto'
  };
  const buttonStyle = {
    marginTop: 20,
    marginBottom: 100
  };
  return (
    <div style={div}>
      <Button
        onClick={login}
        style={buttonStyle}
      >
        Sign in to Diesel
      </Button>
    </div>
  );
}
