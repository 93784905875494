// Module that creates Auth0 Lock objects
//
// This is a separate module so we can mock it out for tests.
//
import auth0 from 'auth0-js';
import { CALLBACK_ROUTE } from '../constants/auth';

import Config from '../utils/Config';

const auth0WebAuth = new auth0.WebAuth({
  domain: Config.Auth0Domain,
  clientID: Config.Auth0ClientId,
  // We want both an access token and an id token. The id token isn't entirely required since we
  // then ask for the profile, but in theory we could do with just the id token since it should come
  // back with enough information in it.
  responseType: 'token id_token',
  // Set the audience to pick up our API in Auth0. We used to ask only for access to the profile
  // (the userinfo url), but now we want access to our API as well so we ask for api. See note below
  // about how scope will give us a token good for both audiences.
  //
  // audience: `https://${Config.Auth0Domain}/userinfo`,
  audience: 'https://api.diesellabs.com',
  // Need the openid scope to get one valid for calling the userinfo endpoint.
  // access:api is our Api's custom scope.
  // profile and email give you access to the detail bits of userinfo.
  //
  // NOTE: At one point we had openid+groups to get the groups stuff appending on, but that is
  // apparently at odds with openid, so we get a access token not valid for the userinfo endpoint.
  // No good, plus our new rule that adds the groups in a custom namespace
  // (https://diesellabs.com/groups) works just fine.
  scope: 'openid profile email nickname access:api',
  redirectUri: `${window.location.origin}${CALLBACK_ROUTE}`
});

export default auth0WebAuth;
