import React from 'react';
import { Row } from 'react-bootstrap';

export default function Subscribe() {
  return (
    <Row>
      Subscribe
    </Row>
  );
}
