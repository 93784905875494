import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ButtonGroup, Card, Table } from 'react-bootstrap';

import styles from './RecommendationsComponent.module.css';
import show from '../assets/images/show.png';
import link from '../assets/images/link.png';
import down from '../assets/images/down.svg';
import Header from './common/Header';
import Footer from './common/Footer';
import Filters from './common/Filters';
import QuotesLoader from './common/QuotesLoader';
import { PlatformSearchLink, ArrayToStr } from '../utils';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

RecommendationsComponent.propTypes = {
  recosObj: PropTypes.object
};
export default function RecommendationsComponent({recosObj}) {
  const {loading, filters, recos} = recosObj;
  return (
    <Container fluid={true} className={`${styles.rec} rec`}>
      <Header />
      <RecommendationsArea
        loading={loading}
        filters={filters}
        recos={recos}
      />
      <Footer />
    </Container>
  );
}

RecommendationsArea.propTypes = {
  loading: PropTypes.bool,
  filters: PropTypes.array,
  recos: PropTypes.object
};
function RecommendationsArea({loading, filters, recos}) {
  if (loading) {
    return (
      <div className="fullHeight">
        <QuotesLoader />
      </div>
    );
  }
  if (recos) {
    const { topic=null, topic_channel=null, topic_genre=null, combinedRecos=null } = recos;
    return (
      <>
        <Navbar topic={topic} topic_channel={topic_channel} topic_genre={topic_genre} />
        <Row className={`${styles.bgBase} pt-1 rec__bgBase`}>
          <Col xs={12} sm={12} lg={10} className={`${styles.rec__holder} ${(filters.filter(filter => filter.selected === true).length === 0) ? 'fCenter' : ''} rec__holder`}>
            {filters.filter(filter => filter.selected === true).length === 0 ?
              <p className={styles.holderText}>{'Modify your filters to receive more recommendations.'}</p>
              :
              <Recommendations filters={filters} combinedRecos={combinedRecos} />
            }
          </Col>
          <Col xs={12} sm={12} lg={2}>
            <Filters />
          </Col>
        </Row>
      </>
    );
  }
  return null;
}

Navbar.propTypes = {
  topic: PropTypes.object,
  topic_channel: PropTypes.array,
  topic_genre: PropTypes.array
};
function Navbar({topic, topic_channel, topic_genre}) {
  const { name='Topic', entity_type, platform_type, thumbnail } = topic;
  const custom_type = entity_type === 'Youtube' || platform_type === 'Youtube' ? 'Video' : entity_type;
  return (
    <Row className={`${styles.recbody} rec__body`}>
      <Col className={`${styles.recnav} rec__nav d-flex align-items-center`}>
        <Col lg={6} sm={12} xs={12} className="rec__userinfo d-flex align-items-center ">
          <img
            src={thumbnail ? thumbnail: show}
            alt="profile"
            className={`${styles.__profile} rec__profileImg `}
            onError={(e)=>{e.target.onerror = null; e.target.src = show;}}
          />
          <span className="d-flex flex-column pl-1 text-white rec__infoText w-100">
            <span className={`${styles.subText} fsize-14 m-0`}>recommendations based on: </span>
            <EllipsisWithTooltip placement="bottom">
              <h5 className="fsize-35 m-0 elipsesText rec__displayText hElipses">{topic.display_name || name}</h5>
            </EllipsisWithTooltip>
          </span>
        </Col>
        <Col lg={6} sm={12} xs={12} className={`${styles.navlabel} rec__navlabel`}>
          <ButtonGroup aria-label="header label" className="rec__mobileAlign">
            { custom_type &&
              <span className={`${styles.hbtn} rec__hbtn `} variant="link">{custom_type}</span>
            }
            { entity_type === 'Youtube' || topic_genre &&
              <span className={`${styles.hbtn} rec__hbtn `} variant="link">{topic_genre}</span>
            }
            { platform_type &&
              <span className={`${styles.hbtn} rec__hbtn `} variant="link">{platform_type}</span>
            }
            { topic_channel &&
              <span className={`${styles.hbtn} rec__hbtn `} variant="link">
                {entity_type === 'Youtube'? 'Youtube' : ArrayToStr(topic_channel)}
              </span>
            }
          </ButtonGroup>
        </Col>
      </Col>
    </Row>
  );
}

Recommendations.propTypes = {
  filters: PropTypes.array,
  combinedRecos: PropTypes.array
};
function Recommendations({filters, combinedRecos}) {
  const [ toggle, setToggle ] = useState(1);
  const handleToggle = () => {
    setToggle(toggle + 1);
  };
  return (
    <CSSTransition in={true} timeout={200} classNames="watchnxt">
      <Card className="mb-4 shadow-sm">
        <Card.Body className={'removeResponsiveInMobile'}>
          <Table responsive className="rec__table mb-0 recTableUI ">
            <thead>
              <tr>
                <th>title </th>
                <th>media type</th>
                <th>genre</th>
                <th>channel</th>
                <th>match</th>
                <th>link</th>
              </tr>
            </thead>
            <tbody>
              <EachFilter recosList={combinedRecos} filters={filters} toggle={toggle} />
            </tbody>
          </Table>
          <p className={`${styles.arrowDown} text-center`}>
            {toggle <= 2 &&
              <span className={styles.actionDown} onClick={handleToggle} >
                <img className={styles.dIcon} src={down} alt="down" />
              </span>
            }
          </p>
        </Card.Body>
      </Card>
    </CSSTransition>
  );
}

EachFilter.propTypes = {
  recosList: PropTypes.array,
  filters: PropTypes.array,
  toggle: PropTypes.number
};
function EachFilter({recosList, filters, toggle}) {
  if (recosList.length === 0) {
    return (<span>{'No Results'}</span>);
  }
  const limit = toggle * 10;
  return (
    <>
      {recosList
        .sort((a,b) => b.affinity.score - a.affinity.score)
        .filter(reco => reco.platform === (filters.find(filter => filter.filterName === reco.platform && filter.selected) || {}).filterName)
        .slice(0, limit)
        .map(reco => {
          const platform = reco.platform;
          const id = reco.affinity.recommendation.id;
          const name = reco.affinity.recommendation.display_name || reco.affinity.recommendation.name || reco.affinity.recommendation.title;
          const type = reco.affinity.recommendation.entity_type || reco.affinity.recommendation.platform_type;
          const custom_type = type === 'Youtube' ? 'Video' : type;
          const platformLinks = reco.platform_links ? reco.platform_links : null;
          const thumb = reco.affinity.recommendation.thumbnail ? reco.affinity.recommendation.thumbnail
            : show;
          const titleLinkClass = reco.affinity.recommendation.is_video ? 'titleLink' : '';
          return (<tr key={id}>
            <td>
              <span className={`${styles.infoText} tableText d-flex align-items-center fsize-20 `}>
                <img
                  className={styles.dImage}
                  src={thumb}
                  alt="profile"
                  onError={(e)=>{e.target.onerror = null; e.target.src = show;}}
                />
                <Link to={`/recommendations/${id}`}
                  className={`mb-0 ml-1 elipsesText titleBold ${titleLinkClass}`}
                >
                  <EllipsisWithTooltip placement="bottom"> {name} </EllipsisWithTooltip>
                </Link>
              </span>
            </td>
            <td>
              <EllipsisWithTooltip placement="bottom">{custom_type} </EllipsisWithTooltip>
            </td>
            <td><EllipsisWithTooltip placement="bottom">{reco.genre && reco.genre[0] || ''}</EllipsisWithTooltip></td>
            <td><EllipsisWithTooltip placement="bottom">{type === 'Youtube' ? 'Youtube' : reco.channel ? ArrayToStr(reco.channel) : ''}</EllipsisWithTooltip></td>
            <td><EllipsisWithTooltip placement="bottom">{`${reco.affinity.score}%`}</EllipsisWithTooltip></td>
            <td><EllipsisWithTooltip placement="bottom">
              <a target="_blank" rel="noopener noreferrer"
                className={`${platform === 'linear' ? 'hideTVPlayButton' : ''}`}
                href={PlatformSearchLink(platform, name, platformLinks)}
              >
                <img className={styles.tLink} src={link} alt="link" />
              </a></EllipsisWithTooltip>
            </td>
          </tr>);
        }
        )}
    </>
  );
}
