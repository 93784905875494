import { RECOS_REQUEST,
  RECOS_SUCCESS,
  RECOS_FAILURE,
  CHECK_FILTERS,
  TOGGLE_FILTERS } from '../constants/action-types';

const initialState = {
  loading: false,
  error: null,
  recos: null,
  filters: null,
  filterToggle: false,
  topicId: null
};
function recommendationsReducer(state = initialState, action) {
  switch (action.type) {
  case RECOS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case RECOS_SUCCESS:
    return {
      ...state,
      loading: false,
      recos: { ...state.recos, ...appendReco(action.payload) },
      filters: frameFilters(action.payload.platforms),
      topicId: getTopicId(action.payload)
    };
  case RECOS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload
    };
  case CHECK_FILTERS:
    return {
      ...state,
      filters: state.filters.map(filter => action.filter === filter.filterName ? {...filter,selected:action.val} : filter)
    };
  case TOGGLE_FILTERS:
    return {
      ...state,
      filterToggle: !state.filterToggle
    };
  default:
    return state;
  }
}

export default recommendationsReducer;

function frameFilters(platforms) {
  const sortPlatforms = scoreSort(platforms);
  return sortPlatforms.map(platform => {
    return {filterName: platform, selected: platform === 'video' || platform === 'channel' ? false : true};
  });
}

function appendReco(payload) {
  const obj = {};
  const id = payload.topic && payload.topic.id;
  const combinedObj = combinedPlatforms(payload.platforms);
  obj[id] = { ...payload, combinedRecos: combinedObj };
  return obj;
}

function getTopicId(payload) {
  return payload.topic && payload.topic.id;
}

function combinedPlatforms(platforms) {
  const arr = [];
  const sortPlatforms = scoreSort(platforms);
  sortPlatforms.forEach(platform => {
    platforms[platform].forEach(reco => arr.push({ ...reco, platform }));
  });
  return arr;
}

function scoreSort(platforms) {
  return Object.keys(platforms)
    .filter(platform => platforms[platform].length > 0)
    .sort((a,b) => platforms[b][0].affinity.score - platforms[a][0].affinity.score);
}
