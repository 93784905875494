import React from 'react';
import quotesJson from '../../assets/quotes.json';
import styles from'./QuotesLoader.module.css';

export default function QuotesLoader() {
  const { quotes } = quotesJson;
  const randomQuote = quotes[quotes.length * Math.random() | 0];
  return (
    <>
      <div className={styles.fullHeight}>
        <div className={styles.quoteLoader}>
          <p className={styles.text}>{randomQuote.quote}</p>
          <span className={styles.name}>{randomQuote.title}</span>
        </div>
      </div>
    </>
  );
}
