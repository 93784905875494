import api from '../middleware/api';
import { RECOS_REQUEST,
  RECOS_SUCCESS,
  RECOS_FAILURE,
  CHECK_FILTERS,
  TOGGLE_FILTERS } from '../constants/action-types';

export function getRecos(topicId) {
  return (dispatch, getState) => {
    dispatch(recosRequest());
    const { recos } = getState().recommendationsReducer;
    if (recos && recos[topicId]) {
      dispatch(recosSuccess(recos[topicId]));
    } else {
      api.fetchGet(`reco/${topicId}/plan`)
        .then(({ data }) => {
          dispatch(recosSuccess(data));
        })
        .catch(() => {
          dispatch(recosFailure('error'));
        });
    }
  };
}

export function getRandomRecos() {
  return (dispatch) => {
    dispatch(recosRequest());
    api.fetchGet('reco/random')
      .then(({ data }) => {
        dispatch(recosSuccess(data));
      })
      .catch(() => {
        dispatch(recosFailure('error'));
      });
  };
}

export function modifyFilters(val, filter) {
  return (dispatch) => {
    dispatch(checkFilters(val, filter));
  };
}

export function toggleFilters() {
  return { type: TOGGLE_FILTERS };
}

// action creators
function recosRequest(payload) {
  return { type: RECOS_REQUEST, payload };
}
function recosSuccess(payload) {
  return { type: RECOS_SUCCESS, payload };
}
function recosFailure(payload) {
  return { type: RECOS_FAILURE, payload };
}
function checkFilters(val, filter) {
  return { type: CHECK_FILTERS, val, filter };
}
