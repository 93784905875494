import React from 'react';
import PropTypes from 'prop-types';
import { Form, Card, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';

import { filtersFormat } from '../../utils';
import { modifyFilters } from '../../actions';
import styles from './Filters.module.css';

Filters.propTypes = {
  filters: PropTypes.array,
  filterToggle: PropTypes.bool,
  modifyFilters: PropTypes.func
};
function Filters({filters, filterToggle, modifyFilters}) {
  const handleCheck = (val, filter) => {
    modifyFilters(val.target.checked, filter);
  };
  const sortedFilters = filters.map(filter => filter)
    .sort((a, b) => filtersFormat[a.filterName] > filtersFormat[b.filterName] ? 1 : -1);
  return (
    <Card className={`rec__filter shadow-sm ${filterToggle ? 'showFilter' : ''}`}>
      <Card.Body className="p-2">
        <h4 className="text-uppercase text-center mb-1 mtext_left filterHeader">Filters</h4>
        <h5 className={styles.subfilter}>platform</h5>
        <Form>
          <ListGroup className="squaredThree">
            <ListGroup.Item className="border-0 pl-3 pt-0">
              {sortedFilters.map(filter =>
                <Form.Group key={filter.filterName} controlId={filtersFormat[filter.filterName]} className="mb-0 custom_check">
                  <Form.Check className="pl-0"
                    type="checkbox"
                    label={filtersFormat[filter.filterName]}
                    onChange={(val) => handleCheck(val, filter.filterName)}
                    defaultChecked={filter.selected}
                  />
                </Form.Group>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Form>
      </Card.Body>
    </Card>
  );
}

const mapStateToProps = state => {
  const { recommendationsReducer } = state;
  return {
    filters: recommendationsReducer.filters,
    filterToggle: recommendationsReducer.filterToggle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    modifyFilters: (val, filter) => dispatch(modifyFilters(val, filter))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
