import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Button} from 'react-bootstrap';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

import Header from './common/Header';
import Footer from './common/Footer';
import QuotesLoader from './common/QuotesLoader';
import styles from './TrendingComponent.module.css';
import trending from '../assets/images/hash.png';
import star from '../assets/images/star.png';

TrendingComponent.propTypes = {
  trendingDetails: PropTypes.object
};
export default function TrendingComponent({trendingDetails}) {
  const [ selectedView, setSelectView ] = useState('trending');
  const toggleView = (view) => {
    setSelectView(view);
  };
  return (
    <Container fluid={true} className={`${styles.trending} trending`}>
      <Row className="fixHeader">
        <Container fluid={true}>
          <Header/>
        </Container>
      </Row>
      { (trendingDetails.loading) &&
        <div className="fullHeight fullHeightTrend">
          <QuotesLoader />
        </div>
      }
      { (trendingDetails.details && trendingDetails.details.trending) &&
        <Trending
          selectedView={selectedView}
          toggleView={toggleView}
          trendingResults={trendingDetails.details.trending}
        />
      }
      { (trendingDetails.details && trendingDetails.details.top) &&
        <MostPopular
          selectedView={selectedView}
          toggleView={toggleView}
          mostPopular={trendingDetails.details.top}
        />
      }
      <Footer />
    </Container>
  );
}

Trending.propTypes = {
  selectedView: PropTypes.string,
  toggleView: PropTypes.func,
  trendingResults: PropTypes.array
};
export function Trending({selectedView, toggleView, trendingResults}) {
  const trendingRef = React.createRef();
  useEffect(() => {
    const windowWidth = window.innerWidth;
    if(windowWidth < 991.98) {
      window.addEventListener('scroll', scrollPage);
    }
  });
  const scrollPage = () => {
    const header = trendingRef.current;
    if(header) {
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      if((window.scrollY + window.innerHeight) > (docHeight - 200)) {
        header.classList.add('stickyHeaderTrending');
      } else {
        header.classList.remove('stickyHeaderTrending');
      }
    }
  };
  return(
    <Row className={`${selectedView === 'trending' ? '' : 'setOneDisplay'}`}>
      <Container fluid={true} className={`${styles.fullHeight}`} ref={trendingRef}>
        <ul className={`${styles.pageTitleSection} fixTitle`}>
          <li className={`${styles.imgHolder}`}>
            <img src={trending} alt="Trending" className={`${styles.proPic}`}/>
          </li>
          <li>
            <h3 className={`${styles.title}`}>Trending</h3>
            <span className={`${styles.timestamp}`}>
              {getFormattedDate(trendingResults[0].metric_date)}
            </span>
          </li>
        </ul>
        <Row className="fixContent">
          <Container className={`${styles.whiteBox}`}>
            <ToggleArrows selectedView={selectedView} toggleView={toggleView} date={trendingResults[0].metric_date}/>
            <ul className={`${styles.mostPopularList}`}>
              <Rows details={trendingResults} />
            </ul>
          </Container>
          <ToggleRadials selectedView={selectedView} toggleView={toggleView} />
        </Row>
      </Container>
    </Row>
  );
}

MostPopular.propTypes = {
  selectedView: PropTypes.string,
  toggleView: PropTypes.func,
  mostPopular: PropTypes.array
};
export function MostPopular({selectedView, toggleView, mostPopular}) {
  const mostPopularRef = React.createRef();
  useEffect(() => {
    const windowWidth = window.innerWidth;
    if(windowWidth < 991.98) {
      window.addEventListener('scroll', scrollPage);
    }
  });
  const scrollPage = () => {
    const header = mostPopularRef.current;
    if(header) {
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      if((window.scrollY + window.innerHeight) > (docHeight - 200)) {
        header.classList.add('stickyHeaderPopular');
      } else {
        header.classList.remove('stickyHeaderPopular');
      }
    }
  };
  return(
    <Row className={`setTwo ${selectedView === 'popular' ? 'setTwoDisplay' : ''}`}>
      <Container fluid={true} className={`${styles.fullHeight}`} ref={mostPopularRef}>
        <ul className={`${styles.pageTitleSection} fixTitle`}>
          <li className={`${styles.imgHolder}`}>
            <img src={star} alt="star" className={`${styles.proPic}`}/>
          </li>
          <li>
            <h3 className={`${styles.title}`}>Most Popular</h3>
            <span className={`${styles.timestamp}`}>
              {getFormattedDate(mostPopular[0].metric_date)}
            </span>
          </li>
        </ul>
        <Row className="fixContent">
          <Container className={`${styles.whiteBox}`}>
            <ToggleArrows toggleView={toggleView} date={mostPopular[0].metric_date} />
            <ul className={`${styles.mostPopularList}`}>
              <Rows details={mostPopular} />
            </ul>
          </Container>
          <ToggleRadials selectedView={selectedView} toggleView={toggleView} />
        </Row>
      </Container>
    </Row>
  );
}

Rows.propTypes = {
  details: PropTypes.array
};
function Rows({details}) {
  const data = details[0].data.data;
  return (
    <>
      {data.map(result =>
        <li key={result.rank}>
          <ul className={`${styles.mostPopularSet}`}>
            <li className={`${styles.countTag}`}><span>{result.rank}</span></li>
            <li>
              <span className={`${styles.listTitle}`}><h3><EllipsisWithTooltip placement="bottom">{result.display_name ? result.display_name : result.name ? result.name : ''}</EllipsisWithTooltip></h3></span>
              <ul className={`${styles.metaList}`}>
                <li><span><EllipsisWithTooltip placement="bottom">{result.entity_type}</EllipsisWithTooltip></span></li>
                <li><span><EllipsisWithTooltip placement="bottom">{result.channel ? result.channel.toString() : ''}</EllipsisWithTooltip></span></li>
                <li><span><EllipsisWithTooltip placement="bottom">{result.genre ? result.genre.toString() : ''}</EllipsisWithTooltip></span></li>
              </ul>
            </li>
            <li className={`${styles.statusIcon}`}>
              <Button className={`${result.trend === 'Up'? styles.up : result.trend === 'Down'? styles.down : styles.neutral} `}></Button>
            </li>
          </ul>
        </li>
      )}
    </>
  );
}

ToggleArrows.propTypes = {
  toggleView: PropTypes.func,
  date: PropTypes.string
};
function ToggleArrows({ toggleView, date}) {
  return (
    <ul className={`${styles.time}`}>
      <li>
        <span>{getFormattedDate(date)}</span>
      </li>
      <li>
        <Button className={`${styles.navArrow} navArrowRight`} onClick={() => toggleView('trending')}></Button>
        <Button className={`${styles.navArrow} navArrowLeft`} onClick={() => toggleView('popular')}></Button>
      </li>
    </ul>
  );
}

ToggleRadials.propTypes = {
  selectedView: PropTypes.string,
  toggleView: PropTypes.func
};
function ToggleRadials({ selectedView, toggleView}) {
  return (
    <Container className="text-center">
      <span className={`${styles.toggleBtn} toggleBtn`}>
        <Button className={`${selectedView === 'trending' ? styles.yes : styles.no}`} onClick={() => toggleView('trending')}></Button>
        <Button className={`${selectedView === 'popular' ? styles.yes : styles.no}`} onClick={() => toggleView('popular')}></Button>
      </span>
    </Container>
  );
}

function getFormattedDate(fDate) {
  const d = new Date(fDate);
  var month = d.toLocaleString('en-US', {month: 'short'});
  var date = d.getDate();
  var year = d.getFullYear();
  return `Week of ${month} ${date}, ${year}`;
}
