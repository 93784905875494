import React, {useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import api from '../../middleware/api';

import styles from './About.module.css';
import feedbackStyles from './Feedback.module.css';

export default function Feedback() {
  const [ name, setName] = useState('');
  const [ lastName, setLastName] = useState('');
  const [ email, setEmail] = useState('');
  const [ subject, setSubject] = useState('');
  const [ message, setMessage] = useState('');
  const [ success, setSuccess] = useState(false);
  const [ error, setError] = useState('');
  const handleForm = () => {
    const req = {};
    if (name)
      req['name'] = name;
    if (lastName)
      req['lastName'] = lastName;
    if (email)
      req['email'] = email;
    if (subject)
      req['subject'] = subject;
    if (message)
      req['message'] = message;
    if (Object.keys(req).length === 5) {
      api.fetchPost('contact-us', req).then(() => {
        setSuccess(true);
      }).catch(() => {
        setError('Something went wrong');
      });
    } else {
      setError('Please fill out the form');
    }
  };
  return (
    <div>
      <Container fluid={true} className={`${styles.about}`}>
        <Header/>
      </Container>
      <Container fluid={false}>
        {success ?
          <Alert variant={'success'} className={`${feedbackStyles.alterMessage} __successMessage mt-2`}>
            Thanks for contacting us
          </Alert>
          :
          <>
            <Row>
              <Col lg={12} className={`${styles.baseTitle} text-center mt-5 mb-5`}>
                <div>Looking to contact us at watchNXT? Fill out the form below!</div>
              </Col>
            </Row>
            <Form className={`${styles.feedBackForm} mb-5`}>
              {error &&
              <Alert variant={'danger'} className={`${feedbackStyles.alterMessage} __alertMessage`}>
                {error}
              </Alert>}
              <Row>
                <Col md={6}>
                  <Form.Group controlId="FirstName">
                    <Form.Label>Name <sup>*</sup></Form.Label>
                    <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} required/>
                    <Form.Text className="text-muted">
                    First Name
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="LastName">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control type="text" value={lastName} onChange={e => setLastName(e.target.value)} required/>
                    <Form.Text className="text-muted">
                    Last Name
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group controlId="Email">
                    <Form.Label>Email Address <sup>*</sup></Form.Label>
                    <Form.Control type="Email" value={email} onChange={e => setEmail(e.target.value)} required/>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group controlId="formBasicSubject">
                    <Form.Label>Subject <sup>*</sup></Form.Label>
                    <Form.Control type="text" value={subject} onChange={e => setSubject(e.target.value)} required/>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className={`${styles.feedBackForm}`} controlId="message">
                    <Form.Label>Message <sup>*</sup></Form.Label>
                    <Form.Control as="textarea" value={message} onChange={e => setMessage(e.target.value)} rows="3" />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Button variant="primary" className={`${styles.btnCustom}`} onClick={handleForm}>Submit</Button>
                </Col>
              </Row>
            </Form>
          </>
        }
        <Footer/>
      </Container>
    </div>
  );
}
