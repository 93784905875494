import api from '../middleware/api';
import { TRENDING_REQUEST,
  TRENDING_SUCCESS,
  TRENDING_FAILURE } from '../constants/action-types';

import { DateOnly } from '../utils';

export function getTreanding() {
  return (dispatch, getState) => {
    const { session, details } = getState().trendingReducer;
    if (session !== DateOnly() || !details) {
      dispatch(trendingRequest());
      api.fetchGet('reco/charts')
        .then(({ data }) => {
          dispatch(trendingSuccess(data));
        })
        .catch(() => {
          dispatch(trendingFailure('error'));
        });
    }
  };
}

// action creators
function trendingRequest(payload) {
  return { type: TRENDING_REQUEST, payload };
}
function trendingSuccess(payload) {
  return { type: TRENDING_SUCCESS, payload };
}
function trendingFailure(payload) {
  return { type: TRENDING_FAILURE, payload };
}
