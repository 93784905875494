// Reducer for the Models model
import decode from 'jwt-decode';

import * as ActionTypes from '../constants/action-types';
import {
  LS_NEXT_PATH_KEY,
  LS_ID_TOKEN_KEY,
  LS_ACCESS_TOKEN_KEY,
  LS_PROFILE_KEY,
  LS_HOMEPAGE_KEY
} from '../constants/local_storage';

/** Default User state is something we compute
 *
 *  This is because there are values that depend on other values. Might Not be exactly how you're
 *  supposed to do things in redux, but it seems like a reasonable balance.
 */
function userStateFromStorage () {
  return {
    idToken: localStorage.getItem(LS_ID_TOKEN_KEY),
    accessToken: localStorage.getItem(LS_ACCESS_TOKEN_KEY),
    profile: JSON.parse(localStorage.getItem(LS_PROFILE_KEY)),
    nextPath: localStorage.getItem(LS_NEXT_PATH_KEY)
  };
}

const DefaultUserStateFromStorage = {
  idToken: null,
  accessToken: null,
  profile: null
  // Do not default this to null only so we can save it while logged out to do redirection once logged in.
  // nextPath: null
};

function computeDerivedState (baseState) {
  return {
    isLoggedIn: !!(baseState.accessToken && !isTokenExpired(baseState.accessToken) && baseState.profile),
    isAdmin: isAdmin(baseState.profile)
  };
}

function defaultUserState () {
  let baseState = userStateFromStorage();
  // Force login again if any of the required keys is null, or if the token is expired
  if (!baseState.idToken || !baseState.accessToken || !baseState.profile || isTokenExpired(baseState.accessToken)) {
    clearLocalStorage();
    baseState = {
      ...baseState,
      ...DefaultUserStateFromStorage
    };
  }
  // TODO: Update Analytics if we have a profile
  return {
    ...baseState,
    ...computeDerivedState(baseState),
    isLoggingIn: false,
    isUpdatingProfile: false
  };
}

function isAdmin (profile) {
  if (profile) {
    const groups = (profile['https://diesellabs.com/groups'] || profile.groups || []);
    return groups.includes('Admin');
  }
  return false;
}

function getTokenExpirationDate (encodedToken) {
  let token = {};
  try {
    token = decode(encodedToken);
  } catch (e) {
    console.log('Error decoding token', e);
  }
  if (!token.exp) { return null; }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

function isTokenExpired (token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}

function clearLocalStorage () {
  // Let's leave this one to allow you to logout on a particular page and be redirected there afterwards.
  // localStorage.removeItem(LS_NEXT_PATH_KEY)
  localStorage.removeItem(LS_ID_TOKEN_KEY);
  localStorage.removeItem(LS_PROFILE_KEY);
  localStorage.removeItem(LS_ACCESS_TOKEN_KEY);
  localStorage.removeItem(LS_HOMEPAGE_KEY);
}

function mergeStateAndComputeDerivedState (state, props) {
  const newState = {
    ...state,
    ...props
  };
  const newStateWithDerived = {
    ...newState,
    ...computeDerivedState(newState)
  };
  return newStateWithDerived;
}

export const user = (state = defaultUserState(), action) => {
  switch (action.type) {
  // Login/out actions

  case ActionTypes.LOGOUT:
    // TODO: update Analytics
    clearLocalStorage();
    console.log(state);
    return {
      ...state,
      ...DefaultUserStateFromStorage,
      ...computeDerivedState({}) // Update derived state.
    };
  case ActionTypes.REQUEST_LOGIN:
    return {
      ...state,
      isLoggingIn: true // Used to track if we're in the middle of the auth flow...
    };
  case ActionTypes.RECEIVE_LOGIN:
    localStorage.setItem(LS_ID_TOKEN_KEY, action.idToken);
    localStorage.setItem(LS_ACCESS_TOKEN_KEY, action.accessToken);
    // TBD about storing idTokenPayload since it probably has enough such that we don't need
    // profile?
    //
    // NOTE: We set isLoggingIn here again since the auth flow forces a redirect and this is the
    // first event fired after we reload and the authenticated callback is fired on auth0 lock.
    const updates = {
      accessToken: action.accessToken,
      idToken: action.idToken,
      isLoggingIn: true
    };
    return mergeStateAndComputeDerivedState(state, updates);
  case ActionTypes.RECEIVE_PROFILE:
    // TODO: Update analytics
    localStorage.setItem(LS_PROFILE_KEY, JSON.stringify(action.profile));
    return mergeStateAndComputeDerivedState(state, { profile: action.profile, profileError: null, isLoggingIn: false });
  case ActionTypes.RECEIVE_PROFILE_FAILURE:
    localStorage.removeItem(LS_PROFILE_KEY);
    return mergeStateAndComputeDerivedState(state, { profile: null, profileError: action.error, isLoggingIn: false });
  case ActionTypes.STORE_POST_LOGIN_REDIRECT:
    localStorage.setItem(LS_NEXT_PATH_KEY, action.redirect);
    return {
      ...state,
      nextPath: action.redirect
    };

    // Actions associated with updating profile

  case ActionTypes.REQUEST_UPDATE_PROFILE:
    return {
      ...state,
      isUpdatingProfile: true
    };
  case ActionTypes.RECEIVE_UPDATE_PROFILE:
    // The profile returned doesn't have everything on it that our profile has, so let's merge the two.
    const newProfile = {
      ...state.profile,
      ...action.response
    };
    localStorage.setItem(LS_PROFILE_KEY, JSON.stringify(newProfile));
    return {
      ...state,
      isUpdatingProfile: false,
      profile: newProfile
    };
  case ActionTypes.REQUEST_UPDATE_PROFILE_FAILURE:
    return {
      ...state,
      isUpdatingProfile: false,
      updateProfileError: action.error
    };
  case ActionTypes.SET_OVERRIDE_ACCESS_TOKEN:
    return {
      ...state,
      overrideAccessToken: action.token
    };
  default:
    return state;
  }
};

export default user;
