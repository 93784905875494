import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import LoginComponent from '../components/LoginComponent';
import { trackGA } from '../utils';
import { LOGIN } from '../constants/modules';
import { loginIfNeeded } from '../actions';

Login.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  nextPath: PropTypes.string
};

function Login({ isLoggedIn, nextPath, dispatch}) {
  console.log(isLoggedIn, nextPath);
  const location = useLocation();
  useEffect(() => {
    trackGA(LOGIN, location);
  }, [location]);

  useEffect(() => {
    dispatch(loginIfNeeded());
  }, []);


  return (
    <LoginComponent
      login={() => dispatch(loginIfNeeded())}
    />
  );
}

const mapStateToProps = state => {
  const {isLoggedIn, nextPath} = state.user;
  return {
    isLoggedIn,
    nextPath
  };
};

export default connect(mapStateToProps)(Login);
