import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Home from './containers/Home';
import Recommendations from './containers/Recommendations';
import WhatsNew from './containers/WhatsNew';
import Trending from './containers/Trending';
import ShowMeSomething from './containers/ShowMeSomething';
import About from './components/common/About';
import Subscribe from './components/common/Subscribe';
import Feedback from './components/common/Feedback';
import TermsOfService from './components/common/TermsOfService';
import PrivacyPolicy from './components/common/PrivacyPolicy';
import Login from './containers/Login';
import LoginCallback from './containers/LoginCallback';
import { initializeLock } from './actions/auth_actions';

class Routes extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props);
    initializeLock(this.props.dispatch);
  }

  render () {
    return (
      <Router>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/recommendations/:topicId">
            <Recommendations />
          </Route>
          <Route path="/whatsnew/:month/:day/:year">
            <WhatsNew />
          </Route>
          <Route path="/trending">
            <Trending />
          </Route>
          <Route path="/showmesomething">
            <ShowMeSomething />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/subscribe">
            <Subscribe />
          </Route>
          <Route path="/feedback">
            <Feedback />
          </Route>
          <Route path="/terms-of-service">
            <TermsOfService />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicy />
          </Route>
          <Route path="/login-callback">
            <LoginCallback />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default connect()(Routes);
